<template>
	<prac-test :test=test>
	</prac-test>
</template>
<script>
import { PracTest } from "@/components";
export default {
  name: "network-layer",
  components: {
    PracTest
  },
  data() {
    return {
      test: {
        date: "January 2019",
        title: "Network Layer",
        subtitle: "Learn more about the Network Layer of the TCP/IP model.",
        description: "Online flashcards on the data network layer.",
        bg: "/img/tests/transport-card-project6.jpg",
        author: "Networks",
        references: "",
        items: [
          {
            front: "What is the transport layer?",
            back:
              "The transport layer connect the network layer wit the application layer to provide data transport from a process of one station to another by providing the needed reliability independent of the physical conditions."
          },
          {
            front:
              "How does the logical communication of the network layer and the transport layer differ?",
            back:
              "<ul><li>Network layer: logical communication between hosts (IP);</li><li>Transport layer: logical communication between processes (IP+port).</li></ul>"
          },
          {
            front:
              "<h4>Why is <b>flow control</b> necessary in network communication?</h4>",
            back:
              "Devices have different speed at which they process incoming data. The sender avoids buffer overflow at the destination."
          },
          {
            front: "Does UDP and TCP have <b>congestion control</b>?",
            back:
              "No, only TCP. UDP has no built-in mechanism for flow control or congestion control."
          },
          {
            front: "Why is UDP faster than TCP?",
            back:
              "TCP has more overhead as it has a larger header, congestion control and needs need for an acknowledgement for the sent packets."
          },
          {
            front:
              "What are two protocols for flow control used by the Transport- and Data Link Layer?",
            back: "Sliding window protocol and atlernating bit (stop & wait)."
          },
          {
            front: "What is congestion control?",
            back:
              "To keep network ztilization high, hosts should react to congestion by adapting their send rates. It's all about ssharing the network with others."
          },
          {
            front: "<h4>What is the <b>Sliding Window</b> Protocol?</h4>",
            back:
              "The sliding window protocol is that provides flow contro, reliability where needed, connection-oriented communication and multiplexing."
          },
          {
            front:
              "Sliding window method: When does the window slide over the remaining data and start to transmit the next packets of data?",
            back:
              "After data is acknolwedged by the destination. The <b>window size</b> represents the maximum number of packets that can be sent without waiting for acknolwedgement."
          },
          {
            front:
              "Why is the <b>alternating bit protocol</b> a special case of the sliding window protocol?",
            back: "Because the alternating bit protocol uses a window of 1 bit."
          },
          {
            front:
              "What is the difference between the alternating bit and sliding window method?",
            back:
              "The window size for alternating bit is always 1 while in the sliding window protocol it can have any window size."
          },
          {
            front:
              "How does TCP ensure that the sender and receiver are in sync?",
            back: "Through the use of the sliding window protocol."
          },
          {
            front: "What is RTT?",
            back:
              "The Round Trip Time is the time (delay) that a packets needs from the source to the destination and back."
          },
          {
            front:
              "Sliding window: How does the sender determine whether a packet has been lost?",
            back: "The sender uses timers to decide when to retransmit."
          },
          {
            front:
              "TCP: How is it the duration of the timer for a retransmit of non-acknowledged transmissions determined?",
            back:
              "The timer is based on the round trip time (RTT). The RTT is constantly updated using the measurements of the timers."
          },
          {
            front:
              "Sliding window: What is the disadvantage of a too long or a too short timer?",
            back:
              "A too short timer may cause extra traffic. And a too long timer can cause larger delays."
          },
          {
            front:
              "Flow control: When does TCP interrupt sending data temporarily?",
            back: "When either the flow control or congestion window is full."
          },
          {
            front: "How are lost packets in TCP and UDP recognized?",
            back:
              "TCP: With the sliding window protocol; UDP does not recognize lost packets."
          },
          {
            front: "What's different between the UDP and TCP checksum field?",
            back:
              "For UDP the checksum is optional. However, both UDP and TCP are based on the IP protocol which has a checksum."
          },
          {
            front: "Why is UDP unreliable?",
            back: "Because it's connectionless."
          },
          {
            front: "<h4>What is a <b>network socket</b></h4>?",
            back:
              "A socket is an ednpoint that identifies a connection on the network for communication."
          },
          {
            front: "What are the socket primitives for TCP?",
            back:
              "<ul><li>SOCKET: create a new endpoint</li><li>BIND an address to a newly created socket.</li><li>LISTEN: waiting to accept connections.</li><li>ACCEPT: establish an incoming connection.</li><li>CONNECT: attempt to establish a connection;</li><li>SEND: send data;</li><li>RECEIVE data;</li><li>CLOSE: release a connection.</li></ul>"
          },
          {
            front:
              "What are some protocols that use UDP (User/Universal Datagram Protocol)",
            back: "DNS; DHCP, RIP, VOIP"
          },
          {
            front:
              "If one uses UDP and ordering is required, how can it be achieved?",
            back:
              "Ordering would have to be managed by the application layer if required."
          },
          {
            front: "What header fields do both, TCP and UDP have incommon?",
            back: "source port, destination port and checksum"
          },
          {
            front: "What is the header size of UDP and TCP?",
            back: "UDP 8 bytes and TCP 20 bytes."
          },
          {
            front: "TCP header: What is the urgent pointer field (URG)?",
            back:
              "URG is set to 1 to indicate a byte offset from the current sequence number where urgent data is to be found. The idea is to use it instead of interrupt messages. However, this field is not often used."
          },
          {
            front: "TCP: When isthe ACK bit set to 1?",
            back:
              "To indicate a valid acknowledgment. If not 1, the field is ignored."
          },
          {
            front: "TCP: What is the RST bit used for?",
            back:
              "To indicate that the connection should be reset because of an error that can't be handled."
          },
          {
            front:
              "TCP: If the SYN bit is 1, does that always mean that a new connection is established?",
            back:
              "No, it is used for a connection request and a connection accept (SYN = 1 and ACK = 1)."
          },
          {
            front: "TCP header: What bit is used to release a connection?",
            back:
              "The FIN bit indicates that the sender is finished with transmission."
          },
          {
            front: "How does demultiplexing work?",
            back:
              "The received segments are delivered to the correct socket (of the application) using the IPs and port numbers."
          },
          {
            front: "How does multiplexing at the send host work?",
            back: "The data is collected from multiple sockets."
          },
          {
            front:
              "Can a web server use a single socket for multiple connecting clients?",
            back: "No, usually every connecting client has a different socket."
          },
          {
            front: 'Why is UDP a "best effort" service?',
            back: "Because segments may be lost and/or out of order."
          },
          {
            front: "What side of a TCP connection has a buffer?",
            back:
              "The receiver has a buffer as the app process can be slow reding the data."
          },
          {
            front:
              "What type of errors can one detect with checksums and what types with sequence numbers?",
            back:
              "<ul><li>Checksums: to detect corrupted packets;</li><li>Sequence numbers: to detect buffer overflow and packet loss.</li></ul>"
          }
        ]
      }
    };
  }
};
</script>
